<template>
  <fieldset v-if="points" class="mt-10 flex flex-col">
    <legend class="text-2xl font-bold font-display text-primary-1-100">
      {{ $t('applyRewardPoints') }}
    </legend>
    <LoyaltyPoints :points="points" :money-value="moneyValue" @input="e => handleLoyaltyPoints(e)" />

    <div class="mt-16 bg-primary-100 h-2" />
  </fieldset>
</template>
<script setup lang="ts">
defineComponent({
  name: 'CheckoutLoyaltyPoints',
});
const { user } = useAuth();
const { applyRewardPoints, isFetching: isApplyingRewardPoints } = useApplyRewardPoints();
const { removeRewardPoints, isFetching: isRemovingRewardPoints } = useRemoveRewardPoints();

async function handleLoyaltyPoints(toggle: boolean) {
  if (toggle) {
    await applyRewardPoints();
    return;
  }

  await removeRewardPoints();
}

const points = computed(() => {
  return user?.value?.points;
});
const moneyValue = computed(() => user?.value?.moneyPoints);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const isFetching = computed(() => isApplyingRewardPoints.value || isRemovingRewardPoints.value);
</script>

<i18n>
{
  "en": {
    "applyRewardPoints": "Apply Reward Points"
  },
  "ar": {
    "applyRewardPoints": "تطبيق نقاط الولاء "
  }
}
</i18n>

<template>
  <section class="w-full text-dark-grey-900">
    <h4 class="font-bold text-font22 mb-5">{{ $t('loyaltyPoints') }}</h4>
    <div class="mt-5 grid grid-cols-2 gap-x-5 lg:gap-x-10">
      <div class="p-6 rounded-2xl shadow-custom-2 text-sm">
        <div class="flex">
          <div>
            <fieldset class="flex items-center justify-start space-x-3">
              <ToggleInput v-model="isChecked" :value="isChecked" />
              <label class="text-font22 font-bold">{{ $t('useYourLoyaltyPoints') }}</label>
            </fieldset>
            <p class="mt-2 text-font22">
              {{ $t('youHave') }}<span class="font-bold"> {{ $t('pointsCount', { points }) }}</span>
            </p>
            <p class="text-primary-900 text-font22 font-bold mt-2">
              = {{ $t('discount') }} <Money :value="moneyValue" class="font-bold" />
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const props = defineProps({
  points: {
    type: Number,
    default: 0,
  },
  moneyValue: {
    type: Number,
    default: 0,
  },
  total: {
    type: Number,
    default: 0,
  },
  isApplied: {
    type: Boolean,
    default: false,
  },
});
const { t: $t } = useI18n({
  useScope: 'local',
});
const emit = defineEmits(['input']);
const isChecked = ref(!!props.isApplied);

const unwatch = watch(
  () => props.isApplied,
  val => {
    if (val) {
      isChecked.value = !!val;
      unwatch();
    }
  },
);
watch(isChecked, newValue => {
  emit('input', !!newValue);
});
</script>
<style lang="postcss" scoped>
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #00572d;
}
</style>

<i18n>
{
  "en": {
    "loyaltyPoints": "Loyalty Points",
    "useYourLoyaltyPoints": "Your Loyalty Points",
    "youHave": "You have",
    "pointsCount": "{points} Points",
    "discount": "Discount"
  }
}
</i18n>
